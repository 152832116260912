<template>
  <div class="col-lg-12">
    <h2 class="score-header">Solves</h2>
    <hr />
    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="sticky-top top-0">
          <tr>
            <th class="cell-timestamp" scope="col">Timestamp</th>
            <th scope="col">Category</th>
            <th scope="col">Title</th>
            <th class="cell-points" scope="col">Points</th>
          </tr>
        </thead>
        <tbody v-if="solves.length == 0">
          <tr class="no-submission-entry">
            <td colspan="4">
              No Submissions
            </td>
          </tr>
        </tbody>
        <tbody v-if="solves.length > 0">
          <tr class="submission-entry" 
            v-for="solve in solves" 
            v-bind:key="solve.id"
            @click="this.navigateToChallenge(solve.challenge.id)">
            <td class="cell-timestamp">{{ formatTimestamp(solve.timestamp) }}</td>
            <td>{{ solve.challenge.category }}</td>
            <td>{{ solve.challenge.title }}</td>
            <td class="cell-points">{{ solve.points }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'UserSolves',

  props: {
    solves: {
      default: []
    }
  },

  components: {
  },

  methods: {
    /**
     * Navigates the user to the provided challenge.
     * @param {*} challengeId The unique ID fo the challenge to navigate to.
     */
    navigateToChallenge(challengeId) {
      this.$router.push({ name: 'challenges', params: { challengeId: challengeId } });
    },

    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleString('en-GB');
    },

    /**
     * Map actions from store
     */
    ...mapActions([
      'selectChallenge'
    ])
  },

  computed: {
  }
}
</script>

<style scoped>
tr.submission-entry {
  cursor: pointer;
}

tr.no-submission-entry {
  text-align: center;
}

.table-responsive {
  max-height: 500px;
}

thead {
  background: white;
  box-shadow: 0px 0px 2px black;
}

.cell-timestamp {
  width: 200px;
}

.cell-points {
  padding-right: 20px;
  text-align: right;
}
</style>