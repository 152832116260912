<template lang="html">
    <!-- Only show challenge if we have a challenge to show -->
    <div :id="challenge.id" class="challenge" v-if="challenge.show">

        <!-- Challenge header -->
        <a class="btn btn-lg header-challenge" role="button" v-on:click="challenge.expanded = !challenge.expanded">
            <span class="col-md-2 header-left"  >
                <i v-if="completed" class="fa fa-star"></i>
                <i v-else class="fa fa-star-o"></i>
            </span>
            <span class="col-md-8 header-center">{{ challenge.title }}</span>
            <span class="col-md-2 header-right" >{{ challenge.points }} points</span>
        </a>

        <!-- Challenge body -->
        <div class="card card-body" v-show="challenge.expanded">

            <!-- Challenge meta data -->
            <div class="row">
                <!-- Authors -->
                <div class="col-md-6 challenge-authors">
                    Author(s): {{ challenge.authors.join(", ") }}
                </div>

                <!-- Solves -->
                <div class="col-md-6 challenge-solves">
                    <span v-if="challenge.n_solves <= 0">Not yet solved by anyone!</span>
                    <span v-else>Solved {{ challenge.n_solves }} time(s). First blood: 
                        <b><router-link :to="{ path: '/user/' + challenge.first_blood.id }">{{ challenge.first_blood.username }}</router-link></b>
                    </span>
                </div>
            </div>

            <!-- Challenge description -->
            <div class="row">
                <div v-html="markdown2HTML(challenge.description)"></div>
            </div>

            <!-- Downloads, Container & Hints -->
            <div class="row">

                <!-- Downloads -->
                <div class="col-md-4">
                    <h4>Downloads</h4>
                    <div v-if="challenge.downloads.length">
                        <ul>
                            <li v-for="download in challenge.downloads" :key="download">
                                <a
                                :href="download"
                                :download="download.replace(/^.*[\\/]/, '')">
                                {{ download.replace(/^.*[\\/]/, '') }}
                                </a>
                            </li>
                        </ul>

                    </div>
                    <span v-else>No downloads available for this challenge.</span>
                </div>

                <!-- Environment -->
                <div class="col-md-4">
                    <h4>Environment</h4>
                    <div v-if="challenge.environment">
                        <div v-if="container">
                            <!-- Stop container button -->
                            <button v-if="containerLock" class="btn btn-lg docker-button" disabled><div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div></button>
                            <button v-else               class="btn btn-lg docker-button docker-button-alert" v-on:click="this.containerStop(this.id)">
                                <b v-if="challenge.environment == 'shared'">Leave environment</b>
                                <b v-else>Stop environment</b>
                            </button>

                            <!-- Show running container(s) -->
                            <span v-if="container.length == 1">Environment is running on:</span>
                            <span v-else                      >Environments are running on:</span>
                            <ul>
                                <li v-for="port in container" :key="port">
                                    <a :href="'http://' + getContainerURL(port)" target="_blank">{{ getContainerURL(port) }}</a>
                                </li>
                            </ul>
                        </div>
                        <div v-else>
                            <!-- Start container button -->
                            <button v-if="containerLock === id" class="btn btn-lg docker-button" disabled><div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div></button>
                            <button v-else-if="containerLock"           class="btn btn-lg docker-button" disabled><b>Waiting for environment '{{ lockedContainerTitle }}'</b></button>
                            <button v-else-if="otherContainer.size"     class="btn btn-lg docker-button docker-button-alert" v-on:click="this.containerSwitch(this.otherContainerId, this.id)"><b>Stop environment '{{ otherContainerTitle }}' &amp; start this environment.</b></button>
                            <button v-else                              class="btn btn-lg docker-button" v-on:click="this.containerStart(this.id)">
                                <b v-if="challenge.environment == 'shared'">Join environment</b>
                                <b v-else>Start environment</b>
                            </button>
                        </div>
                    </div>
                    <span v-else>No environment available for this challenge.</span>
                </div>

                <!-- Hints -->
                <div class="col-md-4">
                    <h4>Hints</h4>
                    <!-- Show hints if available -->
                    <div v-if="challenge.hints.length">
                        <!-- Show hints -->
                        <div v-for="(hint, index) in challenge.hints" :key="hint">
                            <ul v-if="challenge.show_hints[index]">
                                <li>
                                    <span v-html="markdown2HTML(hint, true)"></span>
                                    <button class="btn btn-lg hint-hide" v-on:click="challenge.show_hints[index] = false"><b>Hide hint {{ index+1 }}</b></button>
                                </li></ul>
                            <button v-else class="btn btn-lg hint-button" v-on:click="challenge.show_hints[index] = true"><b>Show hint {{ index+1 }}</b></button>
                        </div>
                    </div>
                    <span v-else>No hints available for this challenge.</span>
                </div>
            </div>

            <!-- Submit flag -->
            <form class="row margin-top" @submit="submitFlag($event)">
                <div class="col-md-10">
                    <input type="text" class="form-control flag-submit" name="flag" value="" placeholder="THS{...}">
                </div>
                <div class="col-md-2">
                    <button type="submit" name="submit" class="btn btn-lg flag-submit-button"><b>Submit flag</b></button>
                </div>
            </form>

            <!-- Show messages -->
            <div v-if="challenge.message_success !== null" class="row">
                <div class="alert alert-success alert-dismissible col-md-12" role="alert">
                  {{ challenge.message_success }}
                  <button type="button" class="btn-close margin-right-sm" v-on:click="challenge.message_success = null"></button>
                </div>
            </div>
            <div v-if="challenge.message_info !== null" class="row">
                <div class="alert alert-warning alert-dismissible col-md-12" role="alert">
                  {{ challenge.message_info }}
                  <button type="button" class="btn-close margin-right-sm" v-on:click="challenge.message_info = null"></button>
                </div>
            </div>
            <div v-if="challenge.message_error !== null" class="row">
                <div class="alert alert-danger alert-dismissible col-md-12" role="alert">
                  <strong>Error:</strong> {{ challenge.message_error }}
                  <button type="button" class="btn-close margin-top-xs margin-right-sm" v-on:click="challenge.message_error = null"></button>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
// Import methods
import { mapActions } from 'vuex'
import { marked     } from 'marked'
import { sanitize   } from 'dompurify'

// Export app
export default {
    name: 'ChallengeObject',

    props: [
        'id'
    ],

    methods: {
        /**
         * Get cookies as an object.
         */
        getCookies(){
            // Initialise result
            var result = {};

            // Get cookies
            var cookies = decodeURIComponent(document.cookie).split(';');

            // Loop over cookies
            for (var cookie of cookies){
                // Get cookie as pair
                cookie = cookie.trim().split('=');
                // Set result
                result[cookie[0]] = cookie[1];
            }

            // Return result
            return result;
        },

        /**
         * Return URL for container port
         */
        getContainerURL(port){
            return  window.location.host.split(':')[0] + ':' + port;
        },

        /**
         * Transform MarkDown to HTML
         */
        markdown2HTML(data, inline){
            if (inline)
                return sanitize(marked.parseInline(data));
            
            return sanitize(marked(data));
        },

        /**
         * Submit flag from a given submit event.
         */
        submitFlag(event){
            // Prevent default submission
            event.preventDefault();

            // Submit flag with correct values
            this.$store.dispatch('submitFlag', {
                id       : this.id,
                flag     : event.target[0].value,
                csrftoken: this.getCookies().csrftoken,
            })

            // Clear flag
            event.target[0].value = '';
        },

        /**
         * Switch active containers
         */
        async containerSwitch(oldContainer, newContainer) {
            await this.containerStop(oldContainer);
            await this.containerStart(newContainer);
        },

        /**
         * Map actions from store
         */
        ...mapActions([
            'containerStart',
            'containerStop',
        ]),
    },

    computed: {
        /**
         * Get the challenge corresponding to the ID.
         */
        challenge() {
            return this.$store.state.challenges[this.id];
        },

        /**
         * Checks whether a challenge is completed.
         */
        completed() {
            return this.$store.state.completed.has(this.id);
        },

        /**
         * Return running container for this challenge
         */
        container() {
            return this.$store.state.containers[this.id];
        },

        /**
         * Return whether a different container is running
         */
        otherContainer() {
            // Get all running containers
            var containers = new Set(Object.keys(this.$store.state.containers));
            // Remove current ID
            containers.delete(this.id);
            // Check if there is a container other than this container
            return containers;
        },

        /**
         * Return ID of other running container
         */
        otherContainerId() {
            // Get all running containers
            var container = Array.from(this.otherContainer);

            // Return container title
            if (container.length > 0){
                return container[0];
            } else {
                return null;
            }
        },

        /**
         * Return title of other running container
         */
        otherContainerTitle() {
            // Return title of other container
            return this.$store.state.challenges[this.otherContainerId].title;
        },

        /**
         * Return title of other running container
         */
        lockedContainerTitle() {
            // Return title of other container
            return this.$store.state.challenges[this.$store.state.containerLock].title;
        },

        /**
         * Get current container lock
         */
        containerLock() {
            return this.$store.state.containerLock;
        },
    },
}
</script>

<style lang="css" scoped>

    .challenge {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .challenge-authors {
        color: #888;
    }

    .challenge-solves {
        color: #888;
        text-align: right;
    }

    .docker-button {
        background-color: #800080;
        margin-bottom: 10px;
        color: white;
        text-align: center;
        width: 100%;
        font-size: 15px;
    }

    .docker-button-alert {
        background-color: #c82333;
    }

    .flag-submit {
        font-weight: 700;
        font-size: 16px;
        width: 100%;
    }

    .flag-submit-button {
        background-color: #800080;
        margin-bottom: 10px;
        color: white;
        text-align: center;
        width: 100%;
        font-size: 15px;
    }

    .header-challenge {
        background-color:#800080;

        color:white;
        font-size:150%;
        font-weight:700;

        width:100%;
    }

    @media only screen
    and (min-width : 768px) {
        .header-center {
            text-align: center;
        }

        .header-left {
            float: left;
            text-align: left;
            width: 130px;
        }

        .header-right {
            float: right;
            text-align: right;
            width: 130px;
        }
    }

    @media only screen
    and (max-width : 768px) {
        .header-center {
            text-align: left;
        }

        .header-left {
            float: left;
            text-align: left;
        }

        .header-right {
            float: right;
            text-align: right;
            width: 130px;
        }
    }



    .hint-button {
        background-color: #800080;
        margin-bottom: 10px;
        color: white;
        text-align: center;
        width: 100%;
        font-size: 15px;
    }

    .hint-hide {
        margin-bottom: 10px;
        background-color: #c82333;
        color: white;
        float: right;
        text-align: right;
        font-size: 15px;
    }
</style>
